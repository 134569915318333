import "channels";
import "controllers";

import Glide, {
  Autoplay,
  Controls,
  Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";
import * as ActiveStorage from "@rails/activestorage";

import Rails from "@rails/ujs";

Rails.start();
ActiveStorage.start();

window.addEventListener("DOMContentLoaded", () => {
  var sliders = document.querySelectorAll(".glide");

  if (document.querySelectorAll(".glide__slides > *").length) {
    sliders.forEach((item) => {
      new Glide(item, {
        type: "carousel",
        startAt: 0,
        perView: 1,
        autoplay: 3000,
        animationDuration: 1300,
      }).mount({ Autoplay, Controls, Swipe });
    });
  }

  // Scroll to top button
  const scrollToTopButton = document.getElementById("scroll-to-top-button");

  scrollToTopButton.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
    });
  });

  // Team slider
  const teamSlider = document.querySelector(".team-slider");

  let slidesPerView = window.innerWidth < 720 ? 1 : 3;

  if (teamSlider) {
    startSliderWhenVisible({
      element: teamSlider,
      options: {
        type: "carousel",
        startAt: 0,
        perView: slidesPerView,
        autoplay: 3000,
        animationDuration: 1000,
      },
      components: { Autoplay, Controls, Swipe },
    });
  }
});

const startSliderWhenVisible = ({ element, options, components }) => {
  const slider = new Glide(element, options).mount(components);
  slider.pause();

  // add intersection observer
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        slider.play();
      }
    });
  });

  observer.observe(element);
};
